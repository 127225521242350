/* playfair-display-regular - latin */
@font-face {
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/playfair-display-v29-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/playfair-display-v29-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/playfair-display-v29-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/playfair-display-v29-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/playfair-display-v29-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/playfair-display-v29-latin-regular.svg#PlayfairDisplay') format('svg'); /* Legacy iOS */
}
/* playfair-display-500 - latin */
@font-face {
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/playfair-display-v29-latin-500.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/playfair-display-v29-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/playfair-display-v29-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/playfair-display-v29-latin-500.woff') format('woff'), /* Modern Browsers */
       url('../fonts/playfair-display-v29-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/playfair-display-v29-latin-500.svg#PlayfairDisplay') format('svg'); /* Legacy iOS */
}
/* playfair-display-600 - latin */
@font-face {
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/playfair-display-v29-latin-600.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/playfair-display-v29-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/playfair-display-v29-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/playfair-display-v29-latin-600.woff') format('woff'), /* Modern Browsers */
       url('../fonts/playfair-display-v29-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/playfair-display-v29-latin-600.svg#PlayfairDisplay') format('svg'); /* Legacy iOS */
}
/* playfair-display-700 - latin */
@font-face {
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/playfair-display-v29-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/playfair-display-v29-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/playfair-display-v29-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/playfair-display-v29-latin-700.woff') format('woff'), /* Modern Browsers */
       url('../fonts/playfair-display-v29-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/playfair-display-v29-latin-700.svg#PlayfairDisplay') format('svg'); /* Legacy iOS */
}
/* playfair-display-800 - latin */
@font-face {
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 800;
  src: url('../fonts/playfair-display-v29-latin-800.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/playfair-display-v29-latin-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/playfair-display-v29-latin-800.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/playfair-display-v29-latin-800.woff') format('woff'), /* Modern Browsers */
       url('../fonts/playfair-display-v29-latin-800.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/playfair-display-v29-latin-800.svg#PlayfairDisplay') format('svg'); /* Legacy iOS */
}
/* playfair-display-900 - latin */
@font-face {
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 900;
  src: url('../fonts/playfair-display-v29-latin-900.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/playfair-display-v29-latin-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/playfair-display-v29-latin-900.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/playfair-display-v29-latin-900.woff') format('woff'), /* Modern Browsers */
       url('../fonts/playfair-display-v29-latin-900.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/playfair-display-v29-latin-900.svg#PlayfairDisplay') format('svg'); /* Legacy iOS */
}

/* inter-100 - latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100;
  src: url('../fonts/inter-v11-latin-100.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/inter-v11-latin-100.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/inter-v11-latin-100.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/inter-v11-latin-100.woff') format('woff'), /* Modern Browsers */
       url('../fonts/inter-v11-latin-100.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/inter-v11-latin-100.svg#Inter') format('svg'); /* Legacy iOS */
}
/* inter-200 - latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 200;
  src: url('../fonts/inter-v11-latin-200.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/inter-v11-latin-200.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/inter-v11-latin-200.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/inter-v11-latin-200.woff') format('woff'), /* Modern Browsers */
       url('../fonts/inter-v11-latin-200.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/inter-v11-latin-200.svg#Inter') format('svg'); /* Legacy iOS */
}
/* inter-300 - latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/inter-v11-latin-300.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/inter-v11-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/inter-v11-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/inter-v11-latin-300.woff') format('woff'), /* Modern Browsers */
       url('../fonts/inter-v11-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/inter-v11-latin-300.svg#Inter') format('svg'); /* Legacy iOS */
}
/* inter-regular - latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/inter-v11-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/inter-v11-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/inter-v11-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/inter-v11-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/inter-v11-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/inter-v11-latin-regular.svg#Inter') format('svg'); /* Legacy iOS */
}
/* inter-500 - latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/inter-v11-latin-500.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/inter-v11-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/inter-v11-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/inter-v11-latin-500.woff') format('woff'), /* Modern Browsers */
       url('../fonts/inter-v11-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/inter-v11-latin-500.svg#Inter') format('svg'); /* Legacy iOS */
}
/* inter-600 - latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/inter-v11-latin-600.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/inter-v11-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/inter-v11-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/inter-v11-latin-600.woff') format('woff'), /* Modern Browsers */
       url('../fonts/inter-v11-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/inter-v11-latin-600.svg#Inter') format('svg'); /* Legacy iOS */
}
/* inter-700 - latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/inter-v11-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/inter-v11-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/inter-v11-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/inter-v11-latin-700.woff') format('woff'), /* Modern Browsers */
       url('../fonts/inter-v11-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/inter-v11-latin-700.svg#Inter') format('svg'); /* Legacy iOS */
}
/* inter-800 - latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 800;
  src: url('../fonts/inter-v11-latin-800.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/inter-v11-latin-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/inter-v11-latin-800.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/inter-v11-latin-800.woff') format('woff'), /* Modern Browsers */
       url('../fonts/inter-v11-latin-800.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/inter-v11-latin-800.svg#Inter') format('svg'); /* Legacy iOS */
}
/* inter-900 - latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 900;
  src: url('../fonts/inter-v11-latin-900.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/inter-v11-latin-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/inter-v11-latin-900.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/inter-v11-latin-900.woff') format('woff'), /* Modern Browsers */
       url('../fonts/inter-v11-latin-900.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/inter-v11-latin-900.svg#Inter') format('svg'); /* Legacy iOS */
}


html {
  box-sizing: border-box;
  font-size:$size--paragraph;
  margin:0;
  height: 100%;
}

body{
  background: $color--bg;
  font-family: $font--paragraph;
}

ul{
  list-style: none;
  padding-inline-start: 0px;
}

a{
  text-decoration: none;
}

.hidden{
  display:none;
}

*, *:before, *:after {
  box-sizing: inherit;
}

.text--hidden{
  position:absolute;
  text-indent:-9999px;
  overflow:hidden;
}

.main{
  width: 100%;
  margin: 0 auto;
  
  p{
    width: 95%;
  }
}

.bold{
  font-weight: 800;
}

.logo{
  height: 60px; 
  width: 60px;
  position: fixed;
  top: 0;
}

.title{
  font-family:$font--title;

  &--first{
    font-size: 75px;
    letter-spacing: 2px;
    line-height: 90%;
    text-align: right;
    margin:0px 8px;
  }

  &--second{
    font-family:$font--title;
    font-size: 50px;
    font-weight: 400;
    text-align:right;
    margin: 10px 8px 0px 0px;
  }

  &--third{
    font-family:$font--title;
    font-size: 32px;
    font-weight: 400;
    text-align:left;
    margin: 15px 16px;
  }

  &--fourth{
    font-family: $font--title;
    font-size: 26px;
  }

  &--position{
    width: fit-content;
    position:relative;
    height: 75px;
    margin: 0;
    display:none;
  }
}

.section__first{
  margin-top: 5%;
}

.section__fifth{
  & p{
    width: 90%;
    margin: 8px 0px 8px 16px;
  }

  & .title--third:nth-of-type(2){
    margin-top: 30px;
  }
}

.section__sixth{
  & img{
    display:none;
  }

  & p{
    width: 90%;
    margin:16px;
    margin-left: 16px;

    & a{
      color: $color--text;
      font-family: $font--paragraph;

      &:hover{
        font-weight:400;
      }

      &:visited{
        text-decoration: underline;
      }
    }
  }
}

.nav{
  display: none;
  font-family: $font--title;

  &__list{
    margin: auto;
    padding-inline-start: 0;
    list-style: none;

      &--el{
        text-align:center;

        & button:hover{
          cursor: pointer;
          animation: animationScale 0.5s ease-in-out;
        }
      }

      &--button{
        background-color: transparent;
        border: none;
      }

  }

  &__link{
    display: inline-block;
    padding: 1em;
    font-size: 1.35em;
    text-decoration: none;
    text-align: center;
    color: $color--text;
    text-transform: uppercase;

      &:focus-visible{
        outline:none;
      }

      &:focus{
        color: $color--text;
        text-decoration:underline;
      }
  }

  &__button{
    position: absolute;
    z-index: 25;
    background: transparent;
    border: none;
    top: 8px;
    right: 8px;
    cursor:pointer;
    outline: none;

      &:hover{
        cursor:pointer;
      }

      &--icon{
        display: inline-block;
        vertical-align: middle;
        width: 2.38em;
        height: 2.38em;
        stroke-width: 5;
        stroke-linecap: square;
        stroke: $color--text;
        transition: stroke 0.4s ease-out, transform 0.4s ease-in-out;

          & path{
            backface-visibility: hidden;
            transition: opacity 0.4s ease-in-out, transform 0.4s ease-in-out;
          }

          .x{
            opacity: 0;
          }

      }
  }
}

body[data-menu] .nav{
display: flex;
position: fixed;
top: 0;
left: 0;
height: 100vh;
width: 100vw;
background: $color--bg;
z-index: 20;
}


body[data-menu] .nav__button--icon  {
  transform: rotate(225deg);
  stroke: $color--text;

  & .h{
    opacity: 0;
  }

  & .x{
    opacity: 1;
  }

  & .t{
    transform: translate(0, 8px);
    transform: translateY(8px);
  }

  & .b{
    transform: translate(0, -8px);
    transform: translateY(-8px);
  }
}


@media 
(-webkit-min-device-pixel-ratio: 2), 
(min-resolution: 192dpi) { 
}


.contact__list{
  text-align:center;
  font-weight: 600;
  margin-top: 25%;

  &--el{
    margin: 10px auto;

    & a{
      color:$color--text;

      &:hover{
        font-weight:400;
      }
    }
  }
}

@keyframes animationScale {
  0% {
   transform:scale(1);
  }
  50% {
   transform:scale(1.1);
  }
  100% {
    transform:scale(1);
   }
 }

.projets--el{
  &:hover{
    animation: animationScale 0.5s ease-in-out;
  }

  & .title--third:nth-of-type(1){
    font-weight: 800;
  }
}

.projets__list{
  padding-inline-start: 0px;
  margin: 50% auto 0px auto;
  width: 100%;

  &--el{
    text-align:center;
    display: none;
    overflow: hidden;

    & p{
      margin: 0;
    }

    & h3 span{
      font-size: 40px;
    }

    & p, a{
      font-size: 12px;
      color: $color--text;
    }

    & h3{
      text-align:center;
    }

    & h4{
      margin-top: 15px;
    }
  }
}

.photo__list--el{
  font-size: 40px;
  font-family: $font--title;
  display: none;

  & img{
    height: auto;
    position:absolute;
    bottom: 30%;
    width: 100%;
  }
}

.crea__list--el{
  display: none;

  & img{
    height: auto;
    max-height: 50%;
    width: 100%;
    position:absolute;
    bottom: 30%;
  }

  &:nth-of-type(13), &:nth-of-type(14), &:nth-of-type(15){
    padding-inline-start: 0px;
    margin: 50% auto 0px auto;
    width: 100%;

  
      & div h3{
        text-align: center;
        color: $color--text;
        font-size: 40px;
      }
  }
}

.slider__el--show, .sliderPhoto__el--show, .sliderCrea__el--show{
    display: block;
    opacity: 1;
    pointer-events: all;
}

.slider__list{
  display:flex;

  &--el{
    background-color:$color--bg;
    position:fixed;
    bottom: 13%;
    max-width: 100vw;

    &:nth-of-type(2){
      right:0;
    }
  
    & img{
      margin: 15px;
      height:auto;
      width: 35px;
      text-align:center;
    }
  }
}

.sliderPhoto__list, .sliderCrea__list{
  display:flex;
}

.sliderPhoto__list--img, .sliderCrea__list--img{
  width: 50px;
}


.container{
  &__music{
  background-color: transparent;
  border-radius: 15px;
  display:flex;
  position:relative;
  margin: 0 0;
  z-index: 10;
  flex-direction: column;
  padding-top: 20vh;
}

&__img{
  display: flex;
  justify-content:center;

  img{
    width:inherit;
    height: 170px;
    width: 170px;
    border-radius: 5px;
    object-fit: cover;
    margin-left: -16px;
  }
}

}

.image{
  &--accueil{
    width: 130%;
    background-image: url("../assets/images/accueil-BW.JPG");
    background-size: cover;
    background-repeat: no-repeat;
    // background-position-y: -60px;
    margin-left: -27%;
    overflow-x: hidden;
    position: fixed;
    bottom: 0px;
    height: 45%;
    // transform: scaleX(-1);
  }

  &--contact{
    width: 100%;
    background-image: url("../assets/images/profil-BW.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    overflow-x: hidden;
    position: fixed;
    background-position: center;
    transform: scaleX(-1);
    bottom: 0px;
    height: 50%;
  }
}


p{
  line-height: 1.3;
}

.text{
  width: 90%;
  margin-left:auto;
  margin-right:auto;
  margin-top: 32px;
}

.button{
  text-align:center;
  position:relative;
  margin-top: 5em;
  margin-left:auto;
  margin-right:auto;
  margin-bottom: 3em;
}

.credits{
  position: fixed; 
  right: 8px;
  margin: 0;
  bottom: 5px;
  font-family: $font--title;
  font-size: 16px;
}

.section__fifth, .section__sixth{
  margin-bottom: 40px;
}

@media screen and (min-width: 360px){
  .title--second{
    margin: 15px 8px 0px 0px;
  }

  .section__first {
    margin-top: 33%;
  }

}

@media screen and (min-width: 375px){

  .section__first {
    margin-top: 18%;
  }
  
  .footer{
    text-align: center;
    position: absolute;
    bottom: 0px;
  }
}

@media screen and (min-width: 410px){
  .section__first {
  margin-top: 25%;
}
}

@media screen and (min-width:600px){
  h1{
    font-size: 80px;
  }

  body{
    font-size: 18px;
    width: auto;
    height: auto;
  }
  
}


@media screen and (min-width:765px){
  .title{
    &--first{
      font-size: 92px;
    }

    &--second{
      font-size: 85px;
    }

    &--third{
      font-size: 55px;
      margin: 30px 0px 15px 16px;
    }

    &--fourth{
      font-family: $font--title;
      font-size: 38px;
    }
  }

  body{
    font-size: 18px;
    width: auto;
    height: auto;
  }

  .section__fourth, .section__fifth{
    width: 85%;
    margin-left: auto;
    margin-right:auto;
    margin-top: 10%;
  }

  .section{
    &__fifth{
      margin-top: 15%;
      width: 75%;
      margin: auto;

      & p{
        width: 100%;
        margin-left:16px;
        margin-right:auto;
      }
    }

    &__first{
      margin-top: 23%;
    }

    &__fourth{
      width: 100%;
    }

    &__sixth{
      width: 75%;
      margin: auto;
      margin-top: 7%;

      & p{
        width: 100%;
        margin-left:16px;
        margin-right:auto;
      }
    }
  }

  .contact__list{
    margin-top: 15%;
  }

  .slider__list{
    display:block;

    &--el{
      z-index: 100;

      & img{
        &:nth-of-type(1){
          left: 0;
          position: relative;
        }
      }
    }
  }

  .projets__list--el h3 span{
    font-size: 72px;
  }

  .image{
    &--accueil{
      height: 55%;
    }

    &--contact{
      height: 60%;
    }
  }

  .photo__list--el img{
    width: 100%;
    height: auto;
  }

  .crea__list--el{
    & div h3 span{
      font-size: 58px;
    }
  }

  .crea__list--el img{
    width: 100%;
    height: auto;
    max-height: inherit;
    position:absolute;
    bottom: 13%;
  }
  
}

@media screen and (min-width: 1280px){

  body{
    font-size: 20px;
    // height: 100vh;
  }

  h1{
    font-size: 100px;
  }

  .title{

    &--first{ 
      z-index: 11;
      position: relative;
    }

    &--position{
      transform: rotate(-90deg);
      width: fit-content;
      position: fixed;
      height: 75px;
      margin: 0;
      bottom: -5px;
      left: 78px;
      display: block;
      transform-origin: left;
      font-size: 100px;
    }
  }

  .credits{
    right: 35px;
  }

  .iframe{
    height: 100vh;
    width: 100%;
  }

  .nav__list--iframe{
    background-color: inherit;
    color: $color--text;

    & a{
      color: $color--text;
    }
  }


  .crea__list--el, .photo__list--el{
    height:max-content;

    & img{
      bottom: 0;
      max-height:80%;
      right: 0;
      width: 50%;
      position: fixed;
      z-index: 50;
    }
  }

  .crea__list--el:nth-of-type(13), .crea__list--el:nth-of-type(14), .crea__list--el:nth-of-type(15) {
    padding-inline-start: 0px;
    margin: inherit;
    bottom: 0;
    max-height: 90%;
    right: 0;
    width: 55%;
    position: fixed;
    z-index: 50;
  }


  .image--accueil{
    width: 60%;
    margin-left: -10%;
    height: 75%;
    position: fixed;
    z-index: 10;
  }

  .image--contact{
    width: 45%;
    height: 70%;
    left: 15%;
    // transform: scaleX(-1);
  }

  .slider__list{
    display:block;

    &--el{
      bottom: -2px;
      z-index: 60;
      transform:rotate(-90deg);
      cursor: pointer;

      & img{
        margin: 10px;
        width: 30px;
      }
  
      &:nth-of-type(1){
        left: calc(100% - 50%);
      }
    }
  }

  .projets__list{
    margin: auto;
    width: fit-content;
    margin-top: 5%;

    &--el{
      display: block;
    }
  }

  .contact__list{
    width: fit-content;
    margin-top: 31%;
    margin-left: 70%;

    &--el{
      margin: 25px auto;
    }
  }

  .projets--el{
    width:auto;
    margin-bottom: 25%;

    & .title--third:nth-of-type(2){
      margin-top: 5px;
    }
  }

  body[data-menu] .nav{
    height: 0vh;
  }
  
  .nav{
    position: absolute;
    display: flex;
    top: 0px;
    width: 100%;

    &__button{
      display: none;
    }

    &__list{
      display: flex;
      background-color: #efefef;
    }

    &__link{
      font-size: 18px;
    }
  }

  .section__first{
    margin-top: 12%;
    margin-right: 20px;
  }

  .section__fifth, .section__sixth{
    margin-top: 0;

    &--el{
      width: 65%;
      margin: -2% 0 0 25%;

      & p{
        width: 100%;
        margin-left: 0;
      }

      & .title--third{
        margin: 30px 0px 15px 0px;
      }
    }
  }

  .section__sixth{
    top: 10%;
  }

}

@media screen and (min-width: 1366px){

  body{
    font-size: 20px;
  }

  h1{
    font-size: 100px;
  }

  .title--position{
    font-size: 85px;
    bottom: -15px;
  }

  .crea__list--el, .photo__list--el{
    height:max-content;

    & img{
      bottom: 0;
      max-height:85%;
      right: 0;
      width: 45%;
      position: fixed;
      z-index: 50;
    }
  }

  .image{
    &--accueil{
      width: 65%;
      margin-left: -10%;
      height: 80%;
      position: fixed;
      z-index: 10;
    }

    &--contact{
      width: 40%;
      height: 80%;
      left: 15%;
    }
  }

  .contact__list{
    margin-top: 20%;
    margin-left: 70%;
  }

  .slider__list{
    &--el{  
      &:nth-of-type(1){
        left: calc(100% - 45%);
      }
    }
  }

  .section{
    &__fifth, &__sixth{
      width: 75%;
    }

    &__sixth{
      margin-top: 5%;
      margin-left: 5%;
      margin: 5% auto; 
    }
  }

}

@media screen and (min-width: 1440px){

  body{
    font-size: 20px;
  }

  .title{
    &--first{
      font-size: 112px;
    }

    &--second, &--position{
      font-size: 100px;
    }

    &--position{
      bottom: 0px;
    }
  }

  .crea__list--el, .photo__list--el{
    height:max-content;

    & img{
      bottom: 0;
      max-height:90%;
      right: 0;
      width: 55%;
      position: fixed;
      z-index: 50;
    }
  }

  .section{

    &__first {
      margin-top: 9%;
    }

    &__fifth {
      margin-top: 3%;
    }

    &__sixth{
      margin: 15% auto;
    }
  }

  .slider__list--el:nth-of-type(1) {
    left: calc(100% - 55%);
  }

  .image--contact{
    width: 50%;
    height: 80%;
  }

  .contact__list {
    margin-top: 35%;
    margin-left: 80%;

    &--el{
      margin: 35px auto;
    }
  }

}

@media screen and (min-width: 1920px){

  body{
    font-size: 20px;
  }

  .title{
    &--first{
      font-size: 126px;
    }
    &--second, .title--position {
      font-size: 115px;
    }
  }

  .image--accueil {
    margin-left: -2%;
  }

  .nav__link {
    font-size: 20px;
  }

  .section__fifth {
    margin-top: 10%;
  }

  .contact__list {
    margin-top: 35%;
    margin-left: 85%;
  }

}

@media screen and (min-width: 2048px){
  .contact__list {
    margin-top: 25%;
    margin-left: 75%;
  }

  .section__sixth {
    margin: 8% auto;
  }
}

@media screen and (min-width: 2304px){

  body{
    font-size: 20px;
  }

  // .nav__button, .logo{
  //   top: 20px;
  //   right: 40px;
  // }

  .section{
    &__first{
      margin-right: 40px;
    }
    &__fifth, &__sixth{
      width: 65%;
    }

    &__fifth .title--third:nth-of-type(2){
      margin-top: 60px;
    }
  }

  .contact__list {
    margin-top: 45%;
    margin-left: 90%;
  }

  .slider__list--el img {
    margin: 15px;
    width: 40px;
  }

}

@media screen and (min-width: 2560px){

  body{
    font-size: 20px;
  }

  h1{
    font-size: 100px;
  }

}
//1280 x 800
//1366 x 768
//1440 x 900
//1920 x 1080
//2304 x 1440
//2560 x 1440