/*variables font */ 
$font--title: 'Playfair Display', serif;
$font--paragraph: 'Inter', sans-serif;

$color--bg: #F1F1F1;
$color--text: #1b1c1b;


/*variables size*/ 
$size--first:3.438em;//55px
$size--second:1.750em;//28px
$size--third:1.563em;//25px
$size--fourth:1.25em;//20px
$size--paragraph:1em;//16px

$size--first--t:3.562em;//57px
$size--second--t:2.389em;//43px
$size--third--t:1.778em;//32px
$size--fourth--t:1.333em;//24px
$size--paragraph--t:1.125em;//18px
